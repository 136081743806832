/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  InteractionRequiredAuthError,
  type IPublicClientApplication,
  type AccountInfo,
} from "@azure/msal-browser";
import ApiClient from "./APIClient";
import { env } from "@/env.mjs";
import { type AxiosResponse } from "axios";
import { type UserData } from "@/context/types";

const client = new ApiClient();

type ResponseTypes =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream";

interface GetMsalTokenProps {
  msalInstance: IPublicClientApplication;
}

export async function getMsalToken({ msalInstance }: GetMsalTokenProps) {
  const msalAccounts = msalInstance.getAllAccounts();
  const request = {
    scopes: [env.NEXT_PUBLIC_AZURE_AD_CLIENT_ID + "/.default"],
    account: msalAccounts[0],
  };

  try {
    const token = await msalInstance.acquireTokenSilent(request);
    return token;
  } catch (error) {
    console.log(JSON.stringify(error, null, 2));
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(request);
    }
  }
}

export default class MSALApiClient {
  msalInstance: IPublicClientApplication;
  msalAccounts: AccountInfo[];

  constructor(msal: IPublicClientApplication, accounts: AccountInfo[]) {
    this.msalInstance = msal;
    this.msalAccounts = accounts;
  }

  async getMsalToken() {
    const request = {
      scopes: [env.NEXT_PUBLIC_AZURE_AD_CLIENT_ID + "/.default"],
      account: this.msalAccounts[0],
    };

    try {
      const token = await this.msalInstance.acquireTokenSilent(request);
      return token;
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return this.msalInstance.acquireTokenPopup(request);
      }
    }
  }

  async fetchAvailableUsers(interval: Date[]) {
    const token = await this.getMsalToken();

    const rtype: ResponseTypes = "json";

    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token?.accessToken || ""}`,
      },
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/available",
      responseType: rtype,
      decompress: true,
      params: {
        from: (interval[0] || new Date()).toISOString(),
        to: (interval[1] || new Date()).toISOString(),
      },
    };

    try {
      const response = await client.fetch(params);
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async fetchUser(email: string) {
    const token = await this.getMsalToken();

    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token?.accessToken || ""}`,
      },
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/get?email=" + email,
    };

    try {
      const response = await client.fetch(params);
      return response.data.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async me(): Promise<AxiosResponse<UserData, any> | null> {
    const token = await this.getMsalToken();

    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token?.accessToken || ""}`,
      },
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/me",
    };

    try {
      const response = await client.fetch(params);
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
