import Image from "next/image";
import { useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { AuthContext } from "@/context/AuthContext";

const Login: React.FC = () => {
  const { instance } = useMsal();
  const { updateUser, fetchCurrentUser } = useContext(AuthContext);

  const handleMsLogin = async () => {
    const result = await instance.loginPopup();
    if (result.accessToken) {
      console.log("GOT ACCESS TOKEN!");
      const user = await fetchCurrentUser();
      updateUser(user);
    }
  };
  return (
    <div className="bg-[url('/loginBg.svg')] bg-cover bg-bottom bg-no-repeat max-[768px]:bg-contain max-[768px]:bg-bottom">
      <main className="flex min-h-screen flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center rounded-md border-2 border-solid border-gray-100 bg-white p-16 max-[1280px]:w-4/12 max-[1142px]:w-5/12  max-[1142px]:p-8 max-[768px]:w-full">
          <Image
            src="/trajectorLogo.png"
            alt="Trajector Logo"
            width={300}
            height={50}
          />
          <p className="pt-12 text-2xl font-bold">Log in to your Account</p>
          <p className="text-xl font-medium">Welcome Back!</p>
          <button
            className="mt-12 flex flex-row items-center justify-center rounded-md border-2 border-solid border-gray-100 p-4 font-medium hover:border-gray-300 hover:font-bold"
            onClick={() => void handleMsLogin()}
          >
            <Image
              src="/microsoftLogo.png"
              alt="Microsoft Logo"
              width={35}
              height={35}
            />{" "}
            <p className="ml-4">Log In with Microsoft</p>
          </button>
        </div>
      </main>
    </div>
  );
};

export default Login;
