/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  type ReactNode,
} from "react";

import NotificationCard from "@/components/shared/notification/notificationCard";
import {
  notificationAction,
  notificationInitialState,
  notificationReducer,
} from "@/reducer";
import { type NotificationContextType } from "@/types/notification";

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);
function Notification({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(
    notificationReducer,
    notificationInitialState
  );

  const deleteNotification = (id: number) => {
    dispatch({
      type: notificationAction.DELETE,
      payload: {
        id: id,
      },
    });
  };

  const closeNotification = (id: number) => {
    dispatch({
      type: notificationAction.INACTIVE,
      payload: {
        id: id,
      },
    });
    setTimeout(() => {
      deleteNotification(id);
    }, 100);
  };

  const notify = (type: string, message: string) => {
    const notificationId = state.notifications.length;
    dispatch({
      type: notificationAction.ADD,
      payload: {
        notification: {
          id: notificationId,
          type: type,
          message: message,
          active: true,
        },
        id: notificationId,
      },
    });
    setTimeout(() => {
      closeNotification(notificationId);
    }, 6000);
    return notificationId;
  };

  const showNotifications = useCallback(
    () => (
      <>
        {state.notifications.map((notification, index) => (
          <NotificationCard
            key={index}
            type={notification?.type}
            message={notification?.message}
          />
        ))}
      </>
    ),
    [state]
  );

  useEffect(() => {
    state;
  }, [state]);

  const value = {
    notifications: state?.notifications,
    notify,
    closeNotification,
  };
  return (
    <>
      <NotificationContext.Provider value={value}>
        <div className="fixed left-0 top-0 z-50 flex h-fit w-full flex-col items-center justify-center gap-3 pt-10">
          {showNotifications()}
        </div>
        {children}
      </NotificationContext.Provider>
    </>
  );
}

export default Notification;
