import { USER_ROLE } from "@/types/user";

export const USER_ROUTES = {
  [USER_ROLE.invoice_specialist]: {
    defaultRoute: "/my-tasks",
    enableRoutes: ["/my-tasks", "/invoicing-opportunity/[id]"],
  },
  [USER_ROLE.invoice_manager]: {
    defaultRoute: "/",
    enableRoutes: [
      "/",
      "/dashboard",
      "/assigned",
      "/unassigned",
      "/invoicing-opportunity/[id]",
    ],
  },
};
