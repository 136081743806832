/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from "react";
import { type PublicClientApplication } from "@azure/msal-browser";
import MSALApiClient from "@/services/MSALApiClient";
import { type User } from "./types";
import { USER_ROLE } from "@/types/user";
import { USER_ROUTES } from "@/lib/constants";
import { useRouter } from "next/router";

type AuthProviderParams = {
  children: React.ReactNode;
  msalInstance: PublicClientApplication;
};

export const AuthContext = React.createContext(
  {} as {
    user: User | null;
    hasUserAccess: (user: User, pathname: string) => boolean;
    updateUser: (user: User | null) => void;
    fetchCurrentUser: () => Promise<User | null>;
    msalInstance: PublicClientApplication;
  }
);

export const AuthProvider = ({
  children,
  msalInstance,
}: AuthProviderParams) => {
  const { push } = useRouter();
  const [user, setUser] = useState<User | null>(null);
  const updateUser = (user: User | null) => {
    setUser(user);
  };

  // Do an initial user fetch
  useEffect(() => {
    if (!user) {
      fetchCurrentUser()
        .then((user) => {
          setUser(user);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const fetchCurrentUser = async () => {
    if (msalInstance.getAllAccounts().length > 0) {
      const api = new MSALApiClient(
        msalInstance,
        msalInstance.getAllAccounts()
      );
      const userDataResponse = await api.me();
      const userData = userDataResponse?.data?.data;
      const user = {
        role: userData?.type.code,
        email: userData?.email,
        fullName: `${userData?.first_name ?? ""} ${userData?.last_name ?? ""}`,
      };

      return user;
    }

    return null;
  };

  const hasUserAccess = (user: User, pathname: string): boolean => {
    switch (user.role) {
      case USER_ROLE.invoice_specialist:
        if (
          USER_ROUTES[USER_ROLE.invoice_specialist].enableRoutes.includes(
            pathname
          )
        ) {
          return true;
        }
        push(USER_ROUTES[USER_ROLE.invoice_specialist].defaultRoute);
        return false;
      case USER_ROLE.invoice_manager:
        if (
          USER_ROUTES[USER_ROLE.invoice_manager].enableRoutes.includes(pathname)
        ) {
          return true;
        }
        push(USER_ROUTES[USER_ROLE.invoice_manager].defaultRoute);
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        hasUserAccess,
        fetchCurrentUser,
        msalInstance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
